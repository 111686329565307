import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default class extends Controller {
  static targets = ["canva"];

  static values = {
    data: Object,
    options: Object,
    total: String,
  };

  connect() {
    const totalValue = this.totalValue;
    var ctx = this.canvaTarget.getContext("2d");
    new Chart(ctx, {
      type: "doughnut",
      data: this.dataValue,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          datalabels: {
            formatter: (value) => {
              let percentage = value + "%";
              return percentage;
            },
            color: "white",
            font: {
              size: 20,
              family: "em outfit",
              weight: "bold",
            },
          },
          legend: {
            display: true,
            position: "bottom",
            labels: {
              usePointStyle: true,
              color: "black",
            },
          },
        },
      },
      plugins: [
        ChartDataLabels,
        {
          id: "text",
          beforeDraw: function (chart, a, b) {
            var width = chart.width,
              height = chart.height,
              ctx = chart.ctx;

            ctx.restore();
            const fontSize = Math.round(height / 200);
            ctx.font = fontSize + "em outfit";
            ctx.textBaseline = "center";

            var textBase = "Total users",
              textBaseX = Math.round(
                (width - ctx.measureText(textBase).width) / 2
              ),
              textBaseY = height / 2.2;

            var numberValue = `${totalValue}`,
              textX = Math.round(
                (width - ctx.measureText(numberValue).width) / 2
              ),
              textY = height / 1.8;

            ctx.fillText(numberValue, textX, textY);
            ctx.fillText(textBase, textBaseX, textBaseY);
            ctx.save();
          },
        },
      ],
    });
  }
}
